import React from 'react';
import { Control, Controller } from 'react-hook-form';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';

// Utils
import { PhoneInputStyles } from '../../inputs/phone-number-input';
import {
  ErrorMessage,
  InputLabel,
  InputLabelWrapper,
} from '../../inputs/inputs';

interface IPhoneField {
  name: string;
  control: Control;
  setTouched: Function;
  touched: boolean;
  error: any;
  autoFocus?: boolean;
  enterKeyHint?:
    | 'search'
    | 'enter'
    | 'done'
    | 'go'
    | 'next'
    | 'previous'
    | 'send';
}

export const PhoneField: React.FC<IPhoneField> = ({
  name,
  control,
  setTouched,
  touched,
  error,
  autoFocus,
  enterKeyHint,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) =>
          value ? isPossiblePhoneNumber(value, 'US') : true,
        required: true,
      }}
      render={({ field: { onChange, value } }) => (
        <PhoneInputStyles>
          <PhoneInput
            inputProps={{ id: 'phone' }}
            autoFocus={autoFocus}
            defaultCountry="US"
            value={value}
            onChange={onChange}
            onFocus={() => setTouched(true)}
            onBlur={() => setTouched(false)}
            enterKeyHint={enterKeyHint}
            type="tel"
          />
          <InputLabelWrapper>
            <InputLabel
              htmlFor="phone"
              active={
                (value !== null && typeof value !== 'undefined') || touched
              }
            >
              Phone Number
            </InputLabel>
          </InputLabelWrapper>
          {!!error && (
            <ErrorMessage style={{ bottom: '12px' }}>
              Please enter a valid US phone number
            </ErrorMessage>
          )}
        </PhoneInputStyles>
      )}
    />
  );
};
