import * as React from 'react';
import { Capacitor } from '@capacitor/core';
import { useWindowWidth } from '../../hooks/useWindowSize';

import {
  InputWrapper,
  InputLabelWrapper,
  InputLabel,
  ErrorMessage,
  ITextInput,
  InputDecoratorLeft,
  InputDecoratorRight,
} from './inputs';

export const RHFTextInput = ({
  id,
  ref,
  autoComplete,
  autoFocus,
  testid,
  name,
  label,
  register,
  options,
  error,
  value,
  decoratorLeft,
  decoratorRight,
  placeholder,
  type = 'text',
  onFocus,
  enterKeyHint,
  tabIndex,
}: ITextInput) => {
  const { isTablet } = useWindowWidth();
  return (
    <InputWrapper
      error={!!error}
      filled={typeof value === 'string' ? !!value.length : !!value}
      decoratorLeft={!!decoratorLeft}
      decoratorRight={!!decoratorRight}
    >
      <input
        id={id}
        ref={ref}
        type={type}
        autoFocus={Capacitor.isNativePlatform() || isTablet ? false : autoFocus}
        {...register(name, options)}
        data-testid={testid}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onFocus={onFocus}
        enterKeyHint={enterKeyHint}
        tabIndex={tabIndex}
      />
      {label && <InputLabelWrapper>
        <InputLabel htmlFor={id} decoratorLeft={!!decoratorLeft}>{label}</InputLabel>
      </InputLabelWrapper>}
      {decoratorLeft && (
        <InputDecoratorLeft>{decoratorLeft}</InputDecoratorLeft>
      )}
      {decoratorRight && (
        <InputDecoratorRight>{decoratorRight}</InputDecoratorRight>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};
